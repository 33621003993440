import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

// !!참고: 테이블 구조의 리스트형 pdf 파일을 만드는 라이브러리

// !!중요: 나눔글꼴은 /proj01/vfsFont/vfs_fonts.js 파일에 위치한다.
// 이 파일을 빌드전에 node_modules/pdfmake/build/ 에 복사해야 한다!
// 사용하지 않을시는 fe/package.json 파일을 편집해야 한다.

pdfMake.vfs = pdfFonts.pdfMake.vfs
pdfMake.fonts = {
  Roboto: {
    normal: 'Roboto-Regular.ttf',
    bold: 'Roboto-Medium.ttf',
    italics: 'Roboto-Italic.ttf',
    bolditalics: 'Roboto-MediumItalic.ttf'
  },
  // 중요: 나눔글꼴 > 나눔스퀘어라운드 레귤러 폰트를 지정한다.
  Nanum: {
    normal: 'NanumSquareRoundR.ttf'
  }
}

// 이 라이브러리를 추가하고 아래처럼 사용하면 된다!
// pdfMake.createPdf(this.pdfDoc).download('optionalName.pdf') // pdf 를 만들고 다운로드
// pdfMake.createPdf(this.pdfDoc).open() // 만들고 새로운 탭에서 오픈!

// !! 공통으로 적용되는 리스트 스타일 정리
const pdfListStyle = {
  header: {
    fontSize: 16,
    bold: false,
    alignment: 'center',
    margin: [0, 5, 0, 5]
  },
  subheader: {
    fontSize: 12,
    bold: false,
    alignment: 'right',
    margin: [0, 5, 0, 0]
  },
  subheaderCalendar: {
    fontSize: 12,
    bold: false,
    alignment: 'center',
    margin: [0, 5, 0, 3]
  },
  tableHeader: {
    bold: false,
    fontSize: 10,
    fillColor: '#eeeeee'
  },
  tableBody: {
    fontSize: 9,
    margin: [0, 5, 0, 15]
  }
}

// export default pdfMake
export {
  pdfMake,
  pdfListStyle
}
