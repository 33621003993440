<template>
  <v-btn
    x-small tile depressed class="mx-1 px-1 blue--text text--darken-4"
    color="blue lighten-4"
    style="font-size: 0.755rem !important;font-weight: 500;letter-spacing: 0.08em;cursor: default;"
  >
    {{ caption }}
  </v-btn>
</template>

<script>
export default {
  props: [ 'caption' ]
}
</script>
