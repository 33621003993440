// 유형코드별 한글제목 매칭
export default (value) => {
  if (!value) return ''
  const typeArray = [
    { text: '', value: 0 },
    { text: '소송', value: 1 },
    { text: '자문', value: 2 },
    { text: '인명부', value: 3 },
    { text: '게시판', value: 4 },
    { text: '전자결재', value: 5 },
    { text: '자동완성키워드', value: 6 },
    { text: '판례/법령', value: 7 },
    // { text: '이메일', value: 8 }, // 사용안함:
    { text: '회의실예약', value: 9 },
    { text: '진행내용', value: 21 },
    { text: '업무', value: 22 },
    { text: '비용', value: 23 },
    { text: '수금', value: 24 },
    { text: '계약', value: 25 },
    { text: '타임시트', value: 26 },
    // { text: '판례법령', value: 27 }, // 사용안함:
    // { text: '전자결재', value: 28 }, // 사용안함:
    { text: '관련사건', value: 31 },
    { text: '연계인명부', value: 32 },
    { text: '연계사건', value: 33 },
    { text: '관계인', value: 35 },
    { text: '첨부파일', value: 36 },
    { text: '댓글', value: 37 },
    { text: '공유링크', value: 38 },
    { text: '구매신청', value: 45 },
    { text: '거래처', value: 46 },
    { text: '물품구매내역', value: 47 },
    { text: '물품정산내역', value: 48 },
    { text: '관리자>사용자', value: 99 },
    { text: '관리자', value: 100 }
  ]
  return typeArray.find(s => s.value === value).text
}
