<template>
  <v-container fluid class="pa-0 ma-0">
  <!-- <v-container fluid :class="$vuetify.breakpoint.xs ? 'pa-0' : 'pa-0'"> -->

    <v-toolbar dense flat>
      <v-btn-toggle
        v-model="toggle_multiple"
        multiple
        dense
        tile
        borderless
        color="primary accent-3"
      >
        <v-btn
          v-model="search.today"
          text
          class="px-1"
          @click="btnClick('today')"
        >
          <v-icon
            small
            :color="search.today ? 'primary' : ''"
          >mdi-calendar</v-icon>
          <span>오늘</span>
        </v-btn>
      </v-btn-toggle>
      <!-- 담당부서 -->
      <div class="ml-2 hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss2"
          :items="select.ss2"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss2')"
        ></v-select>
      </div>
      <!-- 첨부파일구분(관리상태) -->
      <div class="hidden-xs-only" style="width:120px">
        <v-select
          v-model="search.ss1"
          :items="select.ss1"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss1')"
        ></v-select>
      </div>
      <!-- 정보위치 -->
      <div class="hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss3"
          :items="select.ss3"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss3')"
        ></v-select>
      </div>
      <!-- 연계정보 -->
      <div class="hidden-xs-only" style="width:150px">
        <v-select
          v-model="search.ss4"
          :items="select.ss4"
          label=""
          item-value="value"
          item-text="text"
          hide-details
          menu-props="auto"
          class="pa-0 mr-1"
          dense
          style="font-size: 0.785rem !important"
          @change="selectChange('ss4')"
        ></v-select>
      </div>

      <v-spacer></v-spacer>

      <v-btn text small
        class="hidden-xs-only"
        @click.stop="pdfgen"
      >
        <v-icon small>mdi-download</v-icon>
        저장
      </v-btn>
      <!-- !! 검색 아이콘 처리 영역 start -->
      <v-menu
        v-model="searchMenu"
        :close-on-content-click="false"
        :nudge-width="50"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text small class="hidden-xs-only">
            <v-icon small>mdi-magnify</v-icon>
            검색
          </v-btn>
        </template>
        <v-card
          max-width="550"
          min-width="550"
          tile
          elevation="0"
          class="ma-0 pa-0"
        >
          <v-toolbar dark color="primary" dense flat
            class="mb-0 pb-0"
          >
            <v-toolbar-items>
              <v-btn
                dark text class="orange--text text-h6 font-weight-bold" @click="searchPopBtn">검색</v-btn>
              <v-btn
                dark text class="white--text subheading" @click="searchMenu = false">취소</v-btn>
              <v-btn text icon
                @click="initVals"
              >
                <v-tooltip bottom color="primary">
                  <template v-slot:activator="{ on }">
                    <v-icon
                      small
                      v-on="on"
                    >mdi-refresh</v-icon>
                  </template>
                  <span>초기화</span>
                </v-tooltip>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="mt-0 pt-0">
            <v-row
              align="center"
              justify="center"
              no-gutters
              class="ma-0 pa-0"
            >
              <!-- 검색어 검색 -->
              <v-col cols="12" xs="12" class="ma-0 pa-0">
                <v-row
                  no-gutters
                  align="center"
                  justify="center"
                >
                  <div style="width: 140px;">
                    <v-select
                      v-model="search.sf"
                      :items="select.sf"
                      label=""
                      item-value="value"
                      item-text="text"
                      hide-details
                      class="mt-3 mr-3"
                      dense
                      style="font-size: 0.785rem !important"
                    ></v-select>
                  </div>
                  <v-text-field
                    v-model="search.sw"
                    label="검색"
                    append-icon="mdi-magnify"
                    maxlength="20"
                    clearble
                    class="mt-5 mr-3"
                    @keyup.enter="searchPopBtn"
                    @click:append="searchPopIcon"
                  ></v-text-field>
                </v-row>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-menu>
      <!-- 검색 아이콘 처리 영역 end -->
    </v-toolbar>

    <v-toolbar dense flat color="grey lighten-2">
      <div class="text-left mr-0">
        <v-chip small color="primary">
          {{ totalItems }}
        </v-chip>
        <v-btn text icon
          @click="initVals"
        >
          <v-tooltip bottom color="primary">
            <template v-slot:activator="{ on }">
              <v-icon
                small
                v-on="on"
              >mdi-refresh</v-icon>
            </template>
            <span>초기화</span>
          </v-tooltip>
        </v-btn>
      </div>
      <div class="text-left hidden-xs-only">
        <v-chip
          v-for="(item, i) in searchKeywords"
          :key="i"
          small
          :close="!item.isEver"
          color="grey lighten-4"
          class="mr-1"
          @click:close="closeSearchKeyword(item)"
        >{{ item.text }}</v-chip>
      </div>
      <v-spacer></v-spacer>
      <div style="width: 60px">
        <v-select
          :items="itemsPerPageOptions"
          v-model="options.itemsPerPage"
          dense
          class="mt-6"
        ></v-select>
      </div>
    </v-toolbar>

    <v-card :elevation="0">
      <v-data-table
        :headers="headers"
        :items="datas"
        :options.sync="options"
        :server-items-length="totalItems"
        :loading="loading"
        hide-default-header
        hide-default-footer
        no-data-text="데이터가 없습니다"
        no-results-text="검색결과가 없습니다"
        loading-text="로딩중..."
      >
        <!-- 꼼수 : id 필드에 매칭시킨 item 을 가지고 각 row 마다 작업한다. -->
        <template v-slot:[`item.id`]="{ item }">
          <v-list two-line class="pa-0 ma-0">
            <v-list-item class="float-left">
              <v-list-item-content>
                <v-list-item-title class="text-body-2">
                  <v-hover v-slot:default="{ hover }" transition="fade-transition">
                    <div class="grey--text text--darken-1">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="item.type === 36"
                            v-on="on"
                            small
                            :color="!hover ? `${mdiLinkShareIconColor}` : 'warning'"
                          >mdi-paperclip</v-icon>
                        </template>
                        <span>첨부파일</span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="item.type === 38"
                            v-on="on"
                            small
                            :color="!hover ? `${mdiLinkShareIconColor}` : 'warning'"
                          >mdi-link-plus</v-icon>
                        </template>
                        <span>공유링크</span>
                      </v-tooltip>
                      <!-- !! 첨부파일, 공유링크 카테고리 태그(attach file category tag) -->
                      <attach-file-category-tag :caption="item.gubun2"></attach-file-category-tag>
                      <span
                        class="text--primary"
                        :class="hover ? 'font-weight-bold' : 'font-weight-normal'"
                        style="font-size: 0.925rem;font-weight: 450;"
                      >
                        {{ item.str1 }}
                      </span>
                      <!-- 첨부파일 바로보기 -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="item.type === 36"
                            v-on="on"
                            small
                            :color="hover ? 'cyan darken-2' : mdiOpenFileButtonColor"
                            class="pb-1"
                            @click="fileDirectView(item)"
                          >mdi-eye-outline</v-icon>
                        </template>
                        <span>바로보기</span>
                      </v-tooltip>
                      <!-- 공유링크 바로가기 -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="item.type === 38"
                            v-on="on"
                            small
                            :color="hover ? 'warning' : mdiPlusBoxOutlineButtonColor"
                            class="pb-1"
                            @click="shareLinkDirectGo(item)"
                          >mdi-launch</v-icon>
                        </template>
                        <span>바로가기</span>
                      </v-tooltip>
                      <span class="grey--text ml-2" style="font-weight: 400;">
                        {{ strDateFormat2(item.createdAt) }} {{ item.wname }}
                      </span>
                      <span v-show="item.type === 36"
                        class="mx-1 text-caption"
                      >
                        ({{ numberFormat(item.str4) }} bytes)
                      </span>
                      <!-- 첨부파일 바로보기 -->
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="item.type === 36 && hover"
                            v-on="on"
                            small
                            right
                            :color="mdiOpenFileButtonColor"
                            class="ml-2 pb-1"
                            @click="fileDirectView(item)"
                          >mdi-eye-outline</v-icon>
                        </template>
                        <span>바로보기</span>
                      </v-tooltip> -->
                      <!-- 공유링크 바로가기 -->
                      <!-- <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="item.type === 38 && hover"
                            v-on="on"
                            small
                            right
                            :color="mdiPlusBoxOutlineButtonColor"
                            class="ml-1 pb-0"
                            @click="shareLinkDirectGo(item)"
                          >mdi-launch</v-icon>
                        </template>
                        <span>바로가기</span>
                      </v-tooltip> -->
                    </div>
                  </v-hover>
                </v-list-item-title>
                <v-list-item-subtitle>
                  <v-hover v-slot:default="{ hover }">
                    <div class="grey--text text--darken-1 mt-1">
                      <v-chip
                        label outlined x-small class="mx-1 my-0 px-1"
                        :color="iconColors[item.pInfo] || 'blue-grey darken-2'"
                        style="font-size: 0.70rem;font-weight: 500;letter-spacing: 0.08em"
                      >
                        {{ item.pInfo }}
                      </v-chip>
                      <template v-if="item.ppType === 1">
                        {{ item.ppInfo.courtName }} {{ item.ppInfo.caseNum }}{{ item.ppInfo.manageNum ? `/${item.ppInfo.manageNum}` : '' }}
                        <span class="primary--text text--lighten-1">
                          {{ item.dcl }}<span class="grey--text text--darken-1">{{ item.dop }}</span>
                        </span>
                      </template>
                      <template v-else-if="item.ppType === 2">
                        {{ item.ppInfo.manageNum }}
                        <span class="primary--text text--lighten-1">
                          {{ item.dcl }}<span class="grey--text text--darken-1">{{ item.dop }}</span>
                        </span>
                      </template>
                      <template v-else-if="item.ppType === 3">
                        {{ item.ppInfo.name }}
                      </template>
                      {{ item.mgroups ? ` - ${item.mgroups}` : '' }}
                      {{ item.teamName ? ` [${item.teamName}]` : ''}}
                      <!-- {{ strDateFormat2(item.createdAt) }} {{ item.wname }} -->
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            v-show="hover"
                            v-on="on"
                            small
                            right
                            :color="mdiPlusBoxOutlineButtonColor"
                            class="ml-1 pb-0"
                            @click="parentDirectGo(item)"
                          >mdi-launch</v-icon>
                        </template>
                        <span>바로가기</span>
                      </v-tooltip>
                    </div>
                  </v-hover>
                  <!-- <div>{{ item.subContent }}</div> -->
                </v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </template>
      </v-data-table>

      <v-divider></v-divider>

      <div class="text-center pt-3 pb-3">
        <v-pagination
          v-model="options.page"
          :length="pages"
        ></v-pagination>
      </div>
    </v-card>

    <!-- 첨부파일 다운로드시 오버레이 -->
    <v-overlay :value="overlay">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>

  </v-container>
</template>

<script>
import sleep from '@/lib/sleep'

// @: filters
import strDateFormat2 from '@/filters/strDateFormat2'
import numberFormat from '@/filters/numberFormat'
import typeGubun from '@/filters/typeGubun'

// @: pdfmake list
import { pdfMake, pdfListStyle } from '@/lib/pdfmakeList'

// @: tag components
import attachFileCategoryTag from '@/components/tags/attachFileCategoryTag' // 첨부파일,공유링크 카테고리 태그

export default {
  components: {
    attachFileCategoryTag
  },

  data: () => ({
    // 구분: 아이콘 컬러
    mdiPlusBoxOutlineButtonColor: 'info darken-1',
    mdiListEditButtonColor: 'indigo accent-2',
    mdiTrashCanOutlineButtonColor: 'warning darken-1',
    mdiUploadButtonColor: 'primary lighten-1',
    mdiDownloadButtonColor: 'success lighten-1',
    mdiLinkShareIconColor: 'cyan darken-2',
    mdiOpenFileButtonColor: 'orange lighten-1',
    // 구분: 첨부파일
    overlay: false, // 첨부파일 다운로드 로딩시 오버레이
    // 구분: 꼼수 - 헤더스에 별로 의미없는 1개만 매칭시킨다.
    headers: [
      {
        text: '',
        value: 'id',
        align: 'left',
        sortable: false
      }
    ],
    // 구분: 게시판용 변수들
    datas: [],
    totalItems: 0,
    itemsPerPageOptions: [ 15, 30, 50, 100 ],
    // 구분: v-model 과 params 로 백앤드로 전송되는 객체
    params: { // 검색용 인자 객체
      draw: 0,
      where: {},
      sort: [ 'createdAt' ], // 주의: createdAt - 생성일순 주의: 정렬의 갯수만큼 초기값 지정해야 함
      order: [ 'DESC' ],
      offset: 0,
      limit: 1
    },
    // 구분: 초기값 설정이 필요한 변수들
    options: { // v-data-table 에 의해 자동으로 기본값이 들어있는 객체.
      itemsPerPage: 15, // 초기값을 지정했다. 기본값은 15라 매뉴얼에 나옴
      page: 1
    },
    toggle_multiple: [], // v-btn-toggle 의 초깃값이 없는 상태
    loading: false,
    // 구분: 검색에 필요한 변수들 - 초기화 필요
    search: {
      sf: 1, // 검색어 검색 select 의 선택된 필드값
      sw: '', // 검색어 검색 text box input 값
      today: false, // 오늘 검색 버튼 클릭값 - true/false
      star: false, // 중요 검색 버튼 클릭값 - true/false
      ss1: '', // [첨부파일구분] 셀렉트 검색 선택값
      ss2: '', // [담당부서] 셀렉트 검색 선택값
      ss3: '', // [정보위치] 셀렉트 검색 선택값
      ss4: '' // [연계정보] 셀렉트 검색 선택값
    },
    // 구분: 정렬에 필요한 변수들 - 초기화 필요
    sort: {
      default: 2 // [등록일순]기본정렬 매칭 - 정렬의 기본값은 select.sort.value 로 결정
    },
    // 구분: 셀렉트 객체들
    select: {
      sf: [ // 검색어 검색 필드 셀렉트
        { text: '파일/링크명', value: 1 },
        { text: '등록자', value: 2 }
      ],
      ss1: [ // 첨부파일구분 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '첨부파일구분', value: '' }
      ],
      ss2: [ // 담당부서 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '담당부서', value: '' }
      ],
      ss3: [ // 정보위치
        { text: '정보위치', value: '' },
        { text: '소송', value: 1 },
        { text: '자문', value: 2 },
        { text: '인명부', value: 3 }
      ],
      ss4: [ // 연계정보 셀렉트 - 나머지는 카테고리에서 불러온다
        { text: '연계정보', value: '' },
        { text: '기일', value: '기일' },
        { text: '기한', value: '기한' },
        { text: '명령', value: '명령' },
        { text: '제출', value: '제출' },
        { text: '송달', value: '송달' },
        { text: '공고', value: '공고' },
        { text: '접수', value: '접수' },
        { text: '종국', value: '종국' },
        { text: '업무', value: '업무' }
      ],
      defaultSort: [ // 기본 정렬 셀렉트 - order 가 매칭되어있다.
        // { text: '업무일순', value: 1, field: 'date1', order: 'DESC' },
        { text: '등록일순', value: 2, field: 'createdAt', order: 'DESC' }
      ]
    },
    // 구분: 검색어 칩을 사용하는 것들의 배열 - 검색어 칩을 사용하는 요소를 명확히 알 수있다.
    // 타입: 정렬/검색 - sort/select
    // 이름: 요소이름 - sort.defalut
    // select: 비어있지 않은 경우 해당 셀렉트를 이용한다.
    // showSelect: 검색어 검색처럼 셀렉트를 직접 사용하진 않지만 텍스트를 보여줄 경우 사용(select: '' 인 경우에 주로 사용)
    // isEver: true 인 경우 항시 보여야 한다.
    // loading: true 인 경우 로딩시 처리된다
    useSearchKeywords: [
      { type: 'sort', name: 'default', select: 'defaultSort', showSelect: '', isEver: true, loading: true },
      { type: 'search', name: 'ss1', select: 'ss1', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss2', select: 'ss2', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss3', select: 'ss3', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'ss4', select: 'ss4', showSelect: '', isEver: false, loading: false },
      { type: 'search', name: 'sw', select: '', showSelect: '', isEver: false, loading: false }
      // { type: 'search', name: 'sw', select: '', showSelect: 'sf', isEver: false, loading: false }
    ],
    // 구분: 검색어 칩 배열을 위한 변수
    searchKeywords: [],
    // 구분: 기타 변수들
    timeout: null, // delay() 에서 사용하는 변수 - 초기화 불필요
    mgTitle: { // 관리그룹의 제목. 카테고리에서 패칭해서 채운다. 관리그룹이 늘어나면 더 늘려야 한다.
      1: '',
      2: ''
    },
    // 구분: 검색 팝업을 위한 변수
    searchMenu: false,
    // 구분: pdf
    pdfDoc: {
      styles: null,
      defaultStyle: null,
      pageOrientation: 'landscape', // !! 리스트는 가로로
      pageSize: 'A4',
      pageMargins: [ 20, 20, 20, 20 ],
      content: []
    },
    // 구분: 리스트의 v-chip 색상
    iconColors: {
      '기일': 'primary',
      '기한': 'red darken-1',
      '명령': 'green darken-2',
      '제출': 'brown darken-1',
      '송달': 'orange darken-3',
      '공고': 'blue-grey darken-2',
      '접수': 'blue-grey darken-2',
      '종국': 'blue-grey darken-2'
    },
    // 구분:[2021.9.14] 부모(기일/기한..업무 등)의 정보를 중복제거하고 가지고 있는 배열
    pInfos: []
  }),

  computed: {
    setOffset () {
      if (!this.options.page) return 0
      return (this.options.page - 1) * this.options.itemsPerPage
    },
    setLimit () {
      return this.options.itemsPerPage
    },
    pages () { // 페이지네이션 객체에 쓰인다
      if (this.options.itemsPerPage == null || this.totalItems == null) {
        return 0
      }
      return Math.ceil(this.totalItems / this.options.itemsPerPage)
    }
  },

  // 자동감지하고 리스트 재패칭 - 즉각적인 반영이 안될시 delay() 를 써야한다!
  watch: {
    '$route' (to, from) {
      // 내부등록시 리프레시 로직
      if (to.params.id) {
        const paramIds = to.params.id.split('-')
        if (paramIds.length > 1 && paramIds[1] === 'R') {
          // !! 등록
          // 등록시엔 '-R' 이 붙어온다. 이를 통해 리스트 초기화를 시킨다.
          this.initVals().then(() => {
            this.$router.push(`/attach`) // 리스트로 다시 보낸다.
          })
        } else if (paramIds[0] === 'LR') {
          // 주의: 삭제
          // 리스트 리프레시로 파라미터가 날아온 경우 > 삭제의 경우
          this.options.page = 1 // 1페이지로 이동
          this.delay(50) // 리프레시 후
          this.$router.push(`/attach`) // 리스트로 다시 보낸다.
        }
      }
    },
    // 중요: mounted 에서 리스트를 부를 필요없이 이것만으로 초기 로딩이 된다!
    options: {
      handler () {
        this.list()
      },
      deep: true
    },
    'options.itemsPerPage': { // 페이징 갯수 변경 자동감지
      handler () {
        this.options.page = 1 // 1페이지로 이동
        this.params.offset = 0 // 옵셋을 초기화 하지 않으면 에러
        this.delay(50)
      }
    }
  },

  mounted () {
    // 중요: 정상적으로 로그인하지 않으면 콘솔에 에러가 나는데.. 이 에러는 오히려 콘솔창에 생기라고 놔둬야 한다!
    // 이미 router.js 에서 로그인하지 않은경우 처리하므로 다시 '/' 로 뺄 필요도 없다..
    if (!this.$store.state.ui.dbcode) {
      // this.redirect('/')
    }

    // 담당부서 select - 나의 팀목록 불러오기
    this.getTeams().then(teams => {
      if (teams && teams.length > 0) {
        this.select.ss2.push(...teams)
      }
    })

    // !! [2021.3.10 추가] 첨부파일구분 카테고리 참고하여 ss1 셀렉트 만들기
    this.setCates('24').then(({ cates }) => {
      if (cates && cates.length > 0) {
        this.select.ss1.push(...cates.map(c => ({ text: c.gubun1, value: c.num })))
      }
    })

    // 사용안함: [2021.3.10] 관리상태 추가로 에러가 나서 - list() 로 옮김
    // 로딩시 등장해야할 검색어 칩을 찾아서 띄운다. 전돨되는 값은 배열이다.
    // this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
  },

  methods: {
    strDateFormat2,
    typeGubun,
    numberFormat,
    dummy () {
      console.log('dummy test')
    },
    sbpop (e) {
      // 서버에서 수신받은 에러는 router 에서 가로채기 하므로 띄우지 않도록 if (!e.response) 를 검사한다.
      if (!e.response) this.$store.commit('SB_POP', { msg: e.message })
    },
    redirect (to = '') {
      this.$router.push(to)
    },
    initialize () {
      this.list()
    },
    // 참고: watch 로 검색시 약간의 딜레이를 줘야 한다.
    delay (ms = 800) {
      clearTimeout(this.timeout)
      this.timeout = setTimeout(() => {
        this.list()
      }, ms)
    },
    // 중요: 등록시 검색등에 쓰이는 변수 초기화하고 리스트 재패칭
    async initVals () {
      this.doInit().then(() => {
        this.delay(100)
      })
    },
    // 변수 초기화 실행
    doInit () {
      return new Promise((resolve, reject) => {
        // 구분: params 로 백앤드로 전송되는 값
        this.params.draw = 0
        this.params.where = {}
        this.params.sort = [ 'date1' ]
        this.params.order = [ 'DESC' ]
        this.params.offset = 0
        this.params.limit = 1

        // 구분: 검색용(v-model) 변수 - 초기값 설정이 필요
        this.search.sf = 1 // 검색어 검색의 선택된 필드값
        this.search.sw = '' // 검색어 검색의 text box input 값
        this.search.today = false // 오늘 검색 버튼 클릭값 - true/false
        this.search.star = false // 중요 검색 버튼 클릭값 - true/false
        this.search.ss1 = '' // 첨부파일구분 셀렉트 선택값
        this.search.ss2 = '' // 담당부서 셀렉트 선택값
        this.search.ss3 = '' // 정보위치 셀렉트 선택값
        this.search.ss4 = '' // 연계정보 셀렉트 선택값

        // 구분: 정렬 기본값 매칭(갯수만큼)
        this.sort.default = 2 // 등록일순

        // 구분: 기타 초기값 설정이 필요한 변수들
        this.items = []
        this.totalItems = 0
        this.options.itemsPerPage = 15
        this.options.page = 1
        this.toggle_multiple = []
        this.loading = false

        // 구분: 검색어 칩 배열도 초기화
        this.searchKeywords = []
        // 그리고 초기 검색어 칩을 다시 띄움
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))

        // 구분:[2021.9.14]부모의 정보(기일/기한..업무 등)를 중복제거하고 가지고 있는 배열
        this.pInfos = []

        resolve(true)
      })
    },
    // !! 리스트 패칭
    async list () {
      try {
        if (this.loading) return
        this.loading = true

        this.params.draw++
        this.params.offset = this.setOffset
        this.params.limit = this.setLimit

        // !! 검색용 객체 만들기 - where 의 값이 없으면 삭제한다.
        const ws = this.params.where
        for (let key in ws) {
          if (!ws[key]) {
            delete ws[key]
          }
        }
        // console.log(this.params)

        // !! 부드러운 로딩을 위해 ... 임의의 시간 딜레이를 두고 실행
        await sleep(500 - Math.floor(Math.random() * 300))

        const { data } = await this.$axios.get(`lawork/lwc/attachFileList`, { params: this.params })
        if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)

        // 총 검색 갯수(이게 주어져야 페이징이 된다)
        this.totalItems = data.totalItems

        // 중요:[2021.9.14] 부모정보를 패칭해서 가지고 있는 변수
        this.pInfos = data.pInfos

        // 참고: 리스트 데이터 반영
        this.datas = data.list
        if (data.list.length > 0) {
          data.list.forEach(item => {
            // 중요:[2021.3.24] 조부모정보 ppInfo 문자열이 있다면(소송/자문/인명부) 객체로 풀어준다.
            if (item.ppInfo) {
              item.ppInfo = JSON.parse(item.ppInfo)

              // 소송/자문의 경우 당사자(의뢰인/상대방) 처리
              if (item.ppType === 1 || item.ppType === 2) {
                item.dcl = item.ppInfo.clName ? `${item.ppInfo.clType ? `[${item.ppInfo.clType}]` : ''}${item.ppInfo.clName}` : ''
                item.op = item.ppInfo.opName ? `${item.ppInfo.opType ? `[${item.ppInfo.opType}]` : ''}${item.ppInfo.opName}` : ''
                item.dop = (item.dcl && item.op) ? `/${item.op}` : item.op
              }

              // 그룹을 따로 패칭한다.
              item.mgroups = Array.of(item.ppInfo.mgroup1, item.ppInfo.mgroup2).filter(m => m).join('|')
            }

            /*
            // [2021.9.14] 부모정보를 구체적으로 각 리스트에 덧붙인다.(pdf저장용)
            // 사용안함: 리스트를 일단 뿌려야 하는데 속도상 불리해서 제거. 아래 pdf 만드는 함수로 코드 이동시킴
            //         하지만 나중에 살려야 한다면 주석제거하고 사용하기위해 지우진 않음.
            const sb = data.pInfos.find(pInfo => pInfo.id === item.pId)
            item.subContent = `[${item.pInfo}] ${sb.isStar ? '★ ' : ''} ${this.$moment(sb.date1).format('YY.MM.DD')} `

            if (sb.type === 22) {
              // @:[업무]
              item.subContent += sb.str2 // 업무자

              // 장소+시간
              let tmpArr = []
              if (sb.str3) tmpArr.push(sb.str3) // 장소
              // 기일은 시간이 무조건 있으므로 시간없음의 개념이 없다.
              tmpArr.push(`${sb.date1.substr(11, 5)}`)
              if (tmpArr.length > 0) {
                item.subContent += `(${tmpArr.join(' ')})`
              }

              // 업무관리상태
              if (sb.gubun2) item.subContent += ` #${sb.gubun2}`
              //
            } else if (sb.type === 21 && sb.subtype === 1) {
              // @:[기일]
              item.subContent += sb.str1 // 기일명

              // 장소+시간
              let tmpArr = []
              if (sb.str3) tmpArr.push(sb.str3) // 장소
              // 기일은 시간이 무조건 있으므로 시간없음의 개념이 없다.
              tmpArr.push(`${sb.date1.substr(11, 5)}`)
              if (tmpArr.length > 0) {
                item.subContent += `(${tmpArr.join(' ')})`
              }

              // 결과
              if (sb.str2) item.subContent += sb.str2

              // 종국확인 - 종국결과가 있는데 기일에 결과가 없는 경우 '종국확인' 문자열을 띄워야 한다.
              // !! 기일인 경우 종국결과가 있으면 gubun3='종국' 이 들어있어야 한다!
              if (sb.gubun3 === '종국' && !sb.str2) item.subContent += ' [종국확인]'

              // 예정
              if (sb.gubun2) item.subContent += ` #${sb.gubun2}`
              //
            } else if (sb.type === 21 && sb.subtype === 2) {
              // @:[기한]
              item.subContent += sb.str1 // 기한명

              if (sb.str2) item.subContent += ` - ${sb.str2}` // 대상자
              //
            } else if (sb.type === 21 && sb.subtype === 5) {
              // @:[송달]
              item.subContent += sb.str1 // 제목

              if (sb.date2 && sb.str2) { // 도달일자 & 발송결과
                item.subContent += ` - ${this.$moment(sb.date2).format('YY.MM.DD')} ${sb.str2}`
              }
              //
            } else if (sb.type === 21 && sb.subtype === 8) {
              // @:[종국]
              item.subContent += `${sb.str1}${sb.str2 ? ` - ${sb.str2}` : ''}` // 종국결과 & 상세결과

               // 결과평가|향후계획
              let tmpArr = []
              if (sb.str3) tmpArr.push(sb.str3)
              if (sb.str4) tmpArr.push(sb.str4)
              if (tmpArr.length > 0) {
                item.subContent += ` [${tmpArr.join('|')}]`
              }
              //
            } else {
              // @: 그외: 명령, 제출, 공고, 접수
              item.subContent += sb.str1
            }
            // */
          })
        }

        this.loading = false

        // 중요: [2021.3.10] 관리상태 추가로 에러발생 - 여기로 해도 되네?
        this.loadSearchKeywords(this.useSearchKeywords.filter(k => k.loading))
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색처리 메소드 모음
    // 팝업 검색 버튼 클릭 이벤트 핸들러
    async searchPopBtn () {
      try {
        // 1) 검색어 검색 처리
        await this.searchWord()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분:[2021.9.28]팝업검색시 돋보기 아이콘을 클릭할때 검색처리 - 검색어가 없으면 아무것도 안함
    async searchPopIcon () {
      try {
        // 사용안함:검색어가 있는 경우만 searchPopBtn() 를 실행
        // if (this.search.sw) {
        //   await this.searchPopBtn()
        // }
        await this.searchPopBtn()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - true/false 검색 버튼 처리 함수
    async btnClick (elem) {
      try {
        this.search[elem] = !this.search[elem]
        this.params.where[elem] = this.search[elem]

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 셀렉트 검색 처리 메소드
    // 주의: 소송분야 상세(ss3) 때문에 약간 변형됨
    async selectChange (elem) {
      try {
        this.params.where[elem] = this.search[elem]

        // !! 검색어 칩 처리 - 타입은 검색 - search && name = elem
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === elem)
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // [검색] - 검색어 검색 처리 함수
    async searchWord () {
      try {
        if (this.search.sw.length > 0) { // 검색어가 있으면 파라미터에 넣고
          this.params.where.sf = this.search.sf
          this.params.where.sw = this.search.sw
        } else { // 없어도 일단 넣지만 값을 비운다. list() 에서 알아서 삭제된다.
          this.params.where.sf = ''
          this.params.where.sw = ''
        }

        // !! 검색어 칩 처리 - type = search  && name = sw
        const kw = this.useSearchKeywords.find(k => k.type === 'search' && k.name === 'sw')
        await this.setSearchKeywords(kw)

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 정렬처리 메소드 모음 ----
    // this.select.{가변이름}Sort 로 여러개의 소트를 처리할 수 있다.
    // 여러개의 소트가 있어도 처리 가능하다
    // this.params.sort 를 초기화 하고 모든 소트를 새로 만든다.
    async selectOrder () {
      try {
        // 초기화
        this.params.sort = []
        this.params.order = []

        for (let key in this.sort) {
          const selectSortValue = this.sort[key]
          const field = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].field
          const order = this.select[`${key}Sort`].filter(c => c.value === selectSortValue)[0].order

          this.params.sort.push(field)
          this.params.order.push(order)

          // 검색어 칩 - type = sort(정렬)  && name = elem
          const kw = this.useSearchKeywords.find(k => k.type === 'sort' && k.name === key)
          await this.setSearchKeywords(kw)
        }

        this.options.page = 1 // 1 페이지로
        await this.list() // 리스트 리프레시
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: -- 검색어칩 처리 메소드 모음
    // 로딩시 보여줄 검색어 칩을 처리하는 메서드
    async loadSearchKeywords (kw) {
      try {
        if (!Array.isArray(kw)) throw new Error('잘못된 변수전달 방식입니다.')
        kw.forEach(async (k) => {
          await this.setSearchKeywords(k)
        })
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 중요: 검색어 칩을 보여주는 처리를 하는 메서드
    async setSearchKeywords (kw) {
      try {
        // this.searchKeywords 배열에 등록될 객체의 뼈대
        let skw = { text: '', type: kw.type, name: kw.name, isEver: kw.isEver }

        // 기존 같은 타입과 이름의 배열이 있으면 삭제
        const index = this.searchKeywords.findIndex(k => k.type === kw.type && k.name === kw.name)
        if (index > -1) {
          this.searchKeywords.splice(index, 1)
        }

        // 현재값
        const currVal = this[kw.type][kw.name] || ''

        // select 가 있으면 select 에서 보여줄 text 를 가져온다
        if (kw.select) {
          const sel = this.select[kw.select].find(k => k.value === currVal)
          skw.text = (sel.value) ? sel.text : ''
        } else {
          // select 가 아닌 text 입력값은 현재값을 바로 매칭한다.
          // showSelect 가 지정된 경우 해당 셀렉트의 text 를 보여준다.
          if (kw.showSelect) {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `${this.select[kw.showSelect].find(k => k.value === this.search[kw.showSelect]).text} - "${currVal}"`
            } else {
              skw.text = ''
            }
          } else {
            if (currVal) { // 값이 있어야 넣어준다
              skw.text = `"${currVal}"`
            } else {
              skw.text = ''
            }
          }
        }

        if (skw.text) {
          this.searchKeywords.push(skw)
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 검색어 칩을 닫는 메서드
    async closeSearchKeyword (chip) {
      try {
        if (!chip.isEver) { // isEver = true 인 넘은 없앨 수 없다. false 인 경우만 처리
          const kw = this.useSearchKeywords.find(c => c.type === chip.type && c.name === chip.name)
          if (kw.select) {
            // 셀렉트 검색인 경우
            // this.select.sido = '' 처럼 셀렉트의 가장 처음값을 초기값으로 보고 변경시킨다.
            this[kw.type][kw.name] = this.select[kw.name][0].value
            await this.selectChange(kw.name)
          } else {
            //  검색어 검색인 경우
            if (kw.type === 'search' && kw.name === 'sw') {
              this[kw.type][kw.name] = ''
              await this.searchWord()
            }
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 사용안함:[2021.9.1] 첨부파일 다운로드
    async fileDownload (file) {
      try {
        if (!file.str5) throw new Error(`유효한 파일이 아닙니다.`)
        this.overlay = true // 로딩 오버레이 on
        const webContentLink = file.str5 // 이건 다운로드

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우는 그냥 다운로드
          document.location.href = webContentLink // 다운로드 시작!
          // 2초간 로딩 딜레이
          setTimeout(() => {
            this.overlay = false // 로딩 오버레이 off
          }, 2000)
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/case/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            document.location.href = webContentLink // 다운로드 시작!
            // 2초간 로딩 딜레이
            setTimeout(() => {
              this.overlay = false // 로딩 오버레이 off
            }, 2000)
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 첨부파일 바로보기 - 브라우저에서 바로보기
    async fileDirectView (file) {
      try {
        if (!file.str6) throw new Error(`유효한 파일이 아닙니다.`)
        const webViewLink = file.str6 // 이건 브라우저에서 바로보기

        if (this.$store.state.ui.lv === 'M') {
          // !! 관리자인 경우
          const win = window.open(webViewLink, '_blank')
          win.focus()
        } else {
          // !! 관리자가 아닌 경우 .. 공유요청
          const formData = {
            fileId: file.str2 // 구글 드라이브 파일 아이디
          }
          const { data } = await this.$axios.post(`lawork/case/requestGdFileShare`, formData)
          if (!data.success) throw new Error(`오류가 발생하였습니다: ${data.message}`)
          if (data.permessionId) {
            const win = window.open(webViewLink, '_blank')
            win.focus()
          }
        }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 공유링크 바로가기 처리
    async shareLinkDirectGo (item) {
      try {
        // console.log(item)
        if (!item.str2) throw new Error(`유효한 공유주소가 아닙니다.`)

        // * [2022.6.7 수정] http(s):// 가 없는 경우 자동으로 붙여준다.
        let sLink = item.str2
        let httpRegex = /^(http(s)?:\/\/)/
        if (!httpRegex.test(String(item.str2).toLowerCase())) {
          sLink = 'https://' + sLink
        }

        // 다른 탭에서 링크 열기
        const link = document.createElement('a')
        link.href = sLink
        link.target = '_blank'
        link.click()
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 부모 바로가기 - 소송/자문/인명부로 간다.
    async parentDirectGo (item) {
      try {
        const mLink = {
          1: 'case', 2: 'advice', 3: 'client'
        }
        this.$router.push(`/${mLink[item.ppType]}/${item.ppId}`)
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: 유틸리티 함수 모음 -------
    // 카테고리 패칭 함수 - 공통함수로 빼기 난해
    async setCates (type) {
      try {
        const { data } = await this.$axios.get(`admin/cate/getType/${type}`)
        if (!data.success) throw new Error(`list error: ${data.message}`)
        return { cates: data.cates }
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 자신이 속한 팀을 가져온다
    // 기본팀은 필수, (관리자팀,물품구매팀,회의실관리팀은 제외)
    async getTeams () {
      try {
        const { data } = await this.$axios.get('lawork/case/getMyBasicTeamInfo')
        if (!data.success) throw new Error(`오류가 발생하였습니다.: ${data.message}`)

        return data.teams
      } catch (e) {
        this.sbpop(e)
      }
    },
    // 구분: pdf 리스트 제너레이터
    // 중요: /src/lib/pdfmakeList.js 파일에서 공통부분을 처리했고 아래 함수에서는 가변적인 부분만 적용하면된다
    async pdfgen () {
      try {
        // !! 헤더 타이틀
        const pdfHeaderTitle = '사건관리 - 첨부파일 목록'

        // !! pdf 파일정보 - 제목만 넣는다 작성자(author)는 제외
        this.pdfDoc.info = {
          title: pdfHeaderTitle,
          subject: pdfHeaderTitle
        }
        // !! 공통스타일 적용 - 따로 하려면 따로 지정하면 된다.
        this.pdfDoc.styles = pdfListStyle
        this.pdfDoc.defaultStyle = { font: 'Nanum' }

        // !! table body array
        let tBody = []

        // !! 헤더 row 징의
        tBody.push([
          { text: '일자', style: 'tableHeader', alignment: 'center' },
          { text: '유형', style: 'tableHeader', alignment: 'center' },
          { text: '파일유형', style: 'tableHeader', alignment: 'center' },
          // { text: '구분', style: 'tableHeader', alignment: 'center' },
          { text: '등록자', style: 'tableHeader', alignment: 'center' },
          { text: '정보위치', style: 'tableHeader', alignment: 'center' },
          { text: '담당부서', style: 'tableHeader', alignment: 'center' }
        ])

        this.datas.forEach(d => {
          let ppInfo = ''
          if (d.ppType === 1) {
            ppInfo = `${d.ppInfo.caseNum}${d.ppInfo.manageNum ? `/${d.ppInfo.manageNum}` : ''}`
          } else if (d.ppType === 2) {
            ppInfo = d.ppInfo.manageNum
          } else if (d.ppType === 3) {
            ppInfo = d.ppInfo.name
          }

          // 첨부파일과 공유링크의 서브컨텐츠 정보를 만들어준다.
          // let subContent = ''
          // if (d.type === 36) { // 첨부파일
          //   subContent = `${d.str1} (${d.str4} bytes. ${d.str3})${d.str5 ? `\r\n${d.str5}` : ''}`
          // } else { // 공유링크
          //   subContent = `${d.str2}${d.str1 ? `\r\n${d.str1}` : ''}`
          // }

          // !![2021.9.14] 부모(기일/기한..업무)의 내용을 2번째 줄에 매칭하는 것으로 수정함.
          let subContent = ''

          if (d.type === 36) { // 첨부파일
            subContent += `${d.str1} (${d.str4} bytes. ${d.str3})\r\n`
          } else { // 공유링크
            subContent += `${d.str2}${d.str1 ? ` - ${d.str1}` : ''}\r\n`
          }

          const sb = this.pInfos.find(pInfo => pInfo.id === d.pId)
          subContent += `【 ${d.pInfo} 】\r\n${sb.isStar ? '★ ' : ''} ${this.$moment(sb.date1).format('YY.MM.DD')} `
          if (sb.type === 22) {
            // @:[업무]
            subContent += sb.str2 // 업무자

            // 장소+시간
            let tmpArr = []
            if (sb.str3) tmpArr.push(sb.str3) // 장소
            // 기일은 시간이 무조건 있으므로 시간없음의 개념이 없다.
            tmpArr.push(`${sb.date1.substr(11, 5)}`)
            if (tmpArr.length > 0) {
              subContent += `(${tmpArr.join(' ')})`
            }

            // 업무관리상태
            if (sb.gubun2) subContent += ` #${sb.gubun2}`
            //
          } else if (sb.type === 21 && sb.subtype === 1) {
            // @:[기일]
            subContent += sb.str1 // 기일명

            // 장소+시간
            let tmpArr = []
            if (sb.str3) tmpArr.push(sb.str3) // 장소
            // 기일은 시간이 무조건 있으므로 시간없음의 개념이 없다.
            tmpArr.push(`${sb.date1.substr(11, 5)}`)
            if (tmpArr.length > 0) {
              subContent += `(${tmpArr.join(' ')})`
            }

            // 결과
            if (sb.str2) subContent += sb.str2

            // 종국확인 - 종국결과가 있는데 기일에 결과가 없는 경우 '종국확인' 문자열을 띄워야 한다.
            // !! 기일인 경우 종국결과가 있으면 gubun3='종국' 이 들어있어야 한다!
            if (sb.gubun3 === '종국' && !sb.str2) subContent += ' [종국확인]'

            // 예정
            if (sb.gubun2) subContent += ` #${sb.gubun2}`
            //
          } else if (sb.type === 21 && sb.subtype === 2) {
            // @:[기한]
            subContent += sb.str1 // 기한명

            if (sb.str2) subContent += ` - ${sb.str2}` // 대상자
            //
          } else if (sb.type === 21 && sb.subtype === 5) {
            // @:[송달]
            subContent += sb.str1 // 제목

            if (sb.date2 && sb.str2) { // 도달일자 & 발송결과
              subContent += ` - ${this.$moment(sb.date2).format('YY.MM.DD')} ${sb.str2}`
            }
            //
          } else if (sb.type === 21 && sb.subtype === 8) {
            // @:[종국]
            subContent += `${sb.str1}${sb.str2 ? ` - ${sb.str2}` : ''}` // 종국결과 & 상세결과

            // 결과평가|향후계획
            let tmpArr = []
            if (sb.str3) tmpArr.push(sb.str3)
            if (sb.str4) tmpArr.push(sb.str4)
            if (tmpArr.length > 0) {
              subContent += ` [${tmpArr.join('|')}]`
            }
            //
          } else {
            // @: 그외: 명령, 제출, 공고, 접수
            subContent += sb.str1
          }

          tBody.push(
            [
              { rowSpan: 2, alignment: 'center', margin: [0, 10, 0, 0], text: `${this.$moment(d.updatedAt).format('YY.MM.DD')}` },
              d.gubun1,
              d.gubun2,
              // d.pInfo,
              d.wname,
              ppInfo,
              d.teamName
            ],
            ['', { colSpan: 5, text: subContent, alignment: 'left' }]
          )
        })

        let content = [
          { text: pdfHeaderTitle, style: 'header' },
          { text: `${this.$moment().format('YYYY.MM.DD HH:mm')}`, style: 'subheader' },
          {
            style: 'tableBody',
            // layout: 'lightHorizontalLines',
            table: {
              widths: [60, '*', '*', '*', '*', '*'],
              // headerRows: 1, // 다음 페이지로 넘길때 table row 를 반복한다.
              dontBreakRows: true, // !! 다음 페이지로 넘어걸때 row 를 분리하지 않는다.
              body: tBody
            }
          }
        ]
        this.pdfDoc.content = content

        // !! 주어진 설정과 함께 pdf 파일을 다른탭에 열기
        pdfMake.createPdf(this.pdfDoc).open()
      } catch (e) {
        this.sbpop(e)
      }
    }
  }
}
</script>

<style>
/*
  !!참고: 모든 테이블에 공통적용되는 코드지만 각 파일에 있어야 한다.
  새로고침하면 적용이 안되고 적용된 페이지를 들러야 한다.
  v-data-talbe td 의 왼쪽,오른쪽 패딩 제거. 단 style 태그의 scoped 속성을 지워야 적용됨
*/
/* .v-data-table td{ 2020.6.15 변경됨*/
.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding-left: 0;
  padding-right: 0;
}
.v-overflow-btn .v-select__selection--comma:first-child {
  margin-left: 5px;
  margin-right: 0px;
}
.v-overflow-btn .v-input__append-inner {
  width: 30px;
}
/* 중요: 모바일에서 테이블의 기본 값은 justify-content: space between 이다. 이걸 start 로 변경한다! */
.v-data-table__mobile-row {
  justify-content: start;
}
.v-application--is-ltr .v-data-table__mobile-row__cell {
    text-align: left;
}
/*
  참고: 모든 vue2editor 뷰어에 공통적용
*/
#vue2editorViewer p {
  margin-bottom: 0px !important;
}
#vue2editorViewer2 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
#vue2editorViewer3 p { /* 자문에서 사용 */
  margin-bottom: 0px !important;
}
</style>
